import React from 'react';
import { FaArrowUp } from 'react-icons/fa';

const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <button 
      aria-label="Scroll up"
      onClick={scrollToTop} 
      style={{
        position: 'fixed', 
        bottom: '38px', 
        left: '16px', 
        backgroundColor: '#221E44',
        color: '#fff',
        borderRadius: '50%',
        border: 'none',
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        zIndex: '9999'
      }}
    >
      <FaArrowUp />
    </button>
  );
};

export default ScrollToTopButton;