import React from "react";
import {Link} from "gatsby";
import slogan from "../../img/slogan-n-back.svg";
import './Navbar.sass';

const Navbar = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            navBarActiveClass: ""
        };
    }

    toggleHamburger = () => { // toggle the active boolean in the state
        this.setState({
            active: !this.state.active
        },
        // after state has been updated,
            () => { // set the class in state for the navbar accordingly
            this.state.active ? this.setState({navBarActiveClass: "is-active"}) : this.setState({navBarActiveClass: ""});
        });
    };

    render() {
        return (
            <nav className="navbar is-transparent" role="navigation" aria-label="main-navigation"
                style={
                    {background: "linear-gradient(90deg, #412682 7.34%, #49509D 100%)"}
            }>
                <div className="container contenedor">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item logo" title="Logo">
                            <img id="imagen-logo " className="imagen"
                                src={slogan}
                                alt="Crisweb"
                                />
                        </Link>
                        <div className={
                                `navbar-burger burger ${
                                    this.state.navBarActiveClass
                                }`
                            }
                            data-target="navMenu"
                            onClick={
                                () => this.toggleHamburger()
                            }
                            style={
                                {color: "white"}
                        }>
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </div>
                    <div id="navMenu"
                        className={
                            `navbar-menu ${
                                this.state.navBarActiveClass
                            }`
                    }>
                        <div className="navbar-start has-text-centered">
                            <Link className="navbar-item" to="/">
                                Home
                            </Link>
                            <Link className="navbar-item" to="/services">
                                Servicios
                            </Link>
                            <Link className="navbar-item" to="/blog">
                                Blog
                            </Link>
                            <Link className="navbar-item" to="/projects">
                                Proyectos
                            </Link>
                            <Link className="navbar-item button  is-light is-outlined" to="/contact">
                                <strong>Hire Me</strong>
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
};

export default Navbar;
