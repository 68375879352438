import React from "react";
import { Link } from "gatsby";
import slogan from "../../img/logo-text.svg";
import { FaInstagram, FaGithub, FaTwitter, FaLinkedin,  } from 'react-icons/fa';

import './Footer.sass';
const Footer = class extends React.Component {
    render() {
        return (
            <footer className="footer  has-text-white-ter"
                style={
                    {
                        height: "50%",
                        background: "linear-gradient(0deg, #49509D -1.2%, #412682 99.7%)",
                        marginBottom: "auto"
                    }
                }>
                <div className="content has-text-centered">
                    <img src={slogan}
                        alt="crisweb"
                        style={
                            {
                                width: "400px",
                                paddingLeft: "16px",
                                paddingRight: "16px",
                            }
                        } />
                </div>
                <div className="content has-text-centered  has-text-white-ter">
                    <div className="container has-text-white-ter">
                        <div className="custom-columns-footer">
                            <div className="social">
                                <a title="github" href="https://github.com/crisjc6">
                                    <FaGithub style={{
                                        width: '16px',
                                        height: '16px'
                                    }} />
                                </a>
                                <a title="twitter" href="https://twitter.com/crisjc8">
                                <FaTwitter style={{
                                        width: '16px',
                                        height: '16px'
                                    }} />
                                </a>
                                <a title="instagram" href="https://instagram.com/crisweb.me">
                                <FaInstagram style={{
                                        width: '16px',
                                        height: '16px'
                                    }} />
                                </a>
                                <a title="Linkeding" href="https://www.linkedin.com/in/cristhian-jumbo-748934180">
                                <FaLinkedin style={{
                                        width: '16px',
                                        height: '16px'
                                    }} />
                                </a>
                            </div>
                            <div className="footer-links">
                                <section className="menu">
                                    <ul className="menu-list">
                                        <li>
                                            <Link to="/" >
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services" >
                                                Servicios
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/blog">
                                                Blog
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/projects">
                                                Proyectos
                                            </Link>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div className="container has-text-white-ter"
                        style={
                            {
                                color: "#F1F1E6",
                                height: "auto",
                                padding: "16px"
                            }
                        }>
                        Crisweb &copy; {
                            new Date().getFullYear()
                        }- Todos los derechos reservados
                    </div>
                </div>
            </footer>
        );
    }
};

export default Footer;
