import React from 'react';

const BuyMeCoffeeButton = () => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '38px',
        right: '16px',
        backgroundColor: '#221E44',
        color: '#fff',
        borderRadius: '50%',
        border: 'none',
        width: '60px',
        height: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        zIndex: '9999',
        transition: 'transform 0.3s ease',
      }}
      onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1) rotate(10deg)'}
      onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1) rotate(0deg)'}
    >
      <a
        href="https://www.buymeacoffee.com/crisjc6"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
        }}
      >
        <img
          src="https://i.pinimg.com/originals/60/fd/e8/60fde811b6be57094e0abc69d9c2622a.jpg"
          alt="Buy me a coffee"
          style={{
            borderRadius: '50%',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </a>
    </div>
  );
};

export default BuyMeCoffeeButton;
